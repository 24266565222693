import { storeToRefs } from "pinia";
import type { RegistrationParams } from "~/types/onboarding/registrationParams";
import { useExtractQueryParams } from "~/composables/onboarding/useExtractQueryParams";
import { useTokenUserStore } from "~/stores/useTokenUserStore";

type CheckoutParameters = Pick<
  RegistrationParams,
  "goal" | "unitMass" | "startWeight" | "weightGoal" | "country" | "userUuid" | "email" | "password"
> & {
  incomplete?: boolean;
};

export function useCheckout() {
  const { $pinia } = useNuxtApp();
  const { hasAllMandatoryRegistrationParams } = useExtractQueryParams();

  const { registrationParams } = storeToRefs(useOnboardingStore($pinia));
  const { checkoutParams } = storeToRefs(useCheckoutStore($pinia));
  const { userType, user } = storeToRefs(useUserStore($pinia));
  const { tokenUser } = storeToRefs(useTokenUserStore($pinia));

  const trialDays = computed(() => checkoutParams.value.trial_days);

  const checkoutParameters = computed<CheckoutParameters | false>(() => {
    if (userType.value === "user" && user.value) {
      return {
        userUuid: user.value.uuid,
        country: user.value.country,
        email: user.value.email,
        goal: user.value.goal,
        unitMass: user.value.unit_mass,
        startWeight: user.value.start_weight,
        weightGoal: user.value.goal_weight,
        password: "",
        incomplete: false,
      };
    }

    if (userType.value === "tokenUser" && tokenUser.value) {
      return {
        userUuid: tokenUser.value.uuid,
        country: tokenUser.value.country,
        email: tokenUser.value.email,
        goal: tokenUser.value.goal,
        unitMass: tokenUser.value.unit_mass,
        startWeight: tokenUser.value.current_weight,
        weightGoal: tokenUser.value.goal_weight ?? 50,
        password: "",
        incomplete: tokenUser.value.goal_weight === null,
      };
    }

    if (!hasAllMandatoryRegistrationParams(registrationParams.value)) {
      return false;
    }

    return {
      userUuid: registrationParams.value.userUuid,
      password: registrationParams.value.password,
      email: registrationParams.value.email ?? "",
      country: registrationParams.value.country,
      goal: registrationParams.value.goal,
      unitMass: registrationParams.value.unitMass,
      startWeight: registrationParams.value.startWeight,
      weightGoal: registrationParams.value.weightGoal,
      incomplete: registrationParams.value.incomplete,
    };
  });

  const updateCheckoutEmail = (email: string) => {
    if (userType.value === "guest") {
      registrationParams.value.email = email;
    }
  };

  return {
    trialDays,
    checkoutParameters,
    updateCheckoutEmail,
  };
}
